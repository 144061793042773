#Casino {
    width: 40vw;
    height: 40vw;
    /* background-color: ; */
    border: 2px solid black;
    /* position: absolute; */
    right: 3vw;
    bottom: 3vw;
}

#mp {
    width: 100%;
    height: 100%;
}

#slot-machine-canvas {
    width: 100%;
    height: 100%;
    /* background-color: red; */
}
