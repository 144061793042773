:root {
    /* Define the color scheme */
    --primary-color: #10100f;
    --secondary-color: #e9eaeb;
    --accent: #dc82f8;
    --text-primary: rgb(233, 234, 235);
    --text-secondary: #aaaaaa;
}

body {
    color: var(--text-primary);
    font-family: "TitleText", sans-serif, monospace;
    border: none;
    margin: 0;
    padding: 0;
    /* font-weight:  !important; */
}

iframe {
    display: none;
}

#background-container {
    position: absolute !important;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color);
    z-index: -1;
    overflow: hidden;
}

:root {
    --border-padding: 4vw;
    --navbar-height: 3vh;
}

#animation-container {
    width: calc(100vw - var(--border-padding));
    height: calc(100vh - var(--border-padding) - var(--navbar-height));
    margin-left: calc(var(--border-padding) / 2);
    margin-top: calc(var(--border-padding) / 2 + var(--navbar-height));
    border: 0.05vw solid var(--secondary-color);
}
