#project-home {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-template-rows: 1fr;
    grid-template-areas: "categories projects";
    /* overflow-y: scroll; */
    /* scrollbar-color: red orange; */
}

#category-container {
    grid-area: categories;
    /* background-color: red; */
    /* border: 1px solid red; */
    width: calc(100% - 0vw);
    height: calc(100% - 0vw);
    margin-top: 0;
    margin-left: 1vw;
}

#projects-container {
    grid-area: projects;
    /* background-color: blue; */
    /* border: 1px solid blue; */
    width: calc(100% - 0vw);
    height: calc(100% - 0vw);
    margin-right: 1vw;
    margin-top: 0;
    overflow-y: scroll;
    scrollbar-color: black rgba(255, 255, 255, 0);
    scrollbar-width: thin;
    /* direction: rtl; */
}

.project {
    text-align: right;
    margin: 1rem 1rem 2rem 1rem;
}

.project:hover {
    cursor: pointer;
    /* text-decoration: underline; */
}

.project > h2 {
    font-size: 3.5rem;
    margin: 0;
}
.project > p {
    font-size: 1.5rem;
    margin: 0;
    /* margin-bottom: 1vh; */
}

.category {
    position: absolute;
    /* text-decoration: underline; */
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    border: 0;

    /* width: 100%;  */
    justify-content: start;
    /* background-color: blue; */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-100px);
    }
}

.project-view {
    /* border: 3px solid red; */
    width: calc(100% - 2vw);
    /* height: ; */
    margin: 1vw;
    /* span all the grid areas */
    grid-column-start: categories;
    grid-column-end: projects;

    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 10vh 80vh;
    grid-template-areas: "icons title" "description image";
}

#project-info {
    grid-area: description;
    /* background-color: green; */
    overflow-y: scroll;
    /* scrollbar: none; */

    scrollbar-color: var(--text-primary) rgba(255, 255, 255, 0);
    scrollbar-width: thin;
    direction: rtl;
    text-align: left;
}
#project-info > * {
    margin-left: 1vw;
}

#project-images {
    grid-area: image;
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    /* border: 3px solid yellow; */
    overflow-y: scroll;
}

#title {
    grid-area: title;
    /* background-color: red; */
    /* border: 3px solid red; */
    width: 100%;
    height: 100%;
    font-size: 3.5rem;
    text-align: center;
    font-weight: bold;
}

#icons {
    width: 100%;
    height: 100%;
    grid-area: icons;
    display: flex;
    flex: row;
    justify-content: space-between;
}

#back-button {
    margin-left: 1vw;
    height: 100%;
    aspect-ratio: 1/1;
    /* background-color: blue; */
    /* border: 2px solid blue; */
    font-size: 300%;
    text-align: left;
}

#back-button:hover {
    cursor: pointer;
    /* text-decoration: underline; */
}

#inner-icons {
    width: 100%;
    /* height: 100%; WILL BE SET LATER IN CODE */
    /* background-color: green; */
    /* border: 2px solid green; */
    display: flex;
    justify-content: right;
    margin-right: 1vw;
    align-items: start;
}
.icon {
    height: 75%;
    /* aspect-ratio: 1/1; */
    /* margin-top: 0.75vh; */
    margin-left: 1vw;
}

.icon:hover {
    cursor: pointer;
    /* text-decoration: underline; */
}
/* 
#inner-icons > * > * {
    height: 20%;
    aspect-ratio: 1/1;
    margin-top: 0.75vh;
} */
