canvas {
    width: 100%;
    height: 100%;
}

.Background {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    z-index: -1;
}
